// Css
import '../../css/scss/users/password_reset.scss'

// Js
import Vue from 'vue'

Vue.component('password-reset', {
  data() {
    let data = this.$parent.getData()
    this.$parent.breadcrumbsUpdateData([])
    return data
  },
  mounted() {

  },
  methods: {

  }
})
